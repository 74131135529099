<template>
	<section class="section" id="news"
		:style="{ 'background-image': 'url(' + require('@/assets/images/bg/pattern4.png') + ')', 'background-size': 'contain', 'background-position': 'top center' }">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<p class="section-title text-center">新闻动态</p>
					<div class="section-title-border"></div>
					<p class="black7 text-center font-11 padding-top-12">LATEST NEWS</p>
				</div>
			</div>
			<div class="row margin-t-30">
				<div class="col-lg-4 margin-t-30" v-for="(item, index) in shownewsdata" :key="index">
					<div class="myshadow4">
						<a href="javascript: void(0);" @click="readnews(item.id)"><img :src="item.coverimg"
								class="img-fluid myround" /></a>
						<div class="padding-lr-12 padding-bottom-12">
							<p class="mt-3 black6 font-12">{{ item.time }}</p>
							<p>
								<a href="javascript: void(0);" @click="readnews(item.id)" class="onelineshenglue"
									style="font-size: 16px; color:#444444;">{{ item.title }}</a>
							</p>
							<p class="black6 onelineshenglue">{{ item.subtitle }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row arrowarea">
				<div @click="reducepage"><i class="czlicon">&#xe977;</i></div>
				<div @click="addpage"><i class="czlicon">&#xe978;</i></div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'News',
	data() {
		return {
			shownewsdata: [],
			page: 0
		}
	},
	props: {
		news: {
			type: Array,
			default: function () { return []; }
		},
		pages: {
			type: Number,
			default: function () { return 0; }
		}
	},
	watch: {
		news(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.shownewsdata = this.news.slice(this.page * 3, (this.page + 1) * 3);
			}
		}
	},
	mounted() {
		this.shownewsdata = this.news.slice(0, 3);
		console.log(this.news);
		this.$nextTick(() => {
			const layui = window.layui;
			layui.use(["layer"], () => {
				var layer = layui.layer;
			});
		});
	},
	methods: {
		readnews(id) {
			layer.open({
				type: 2,
				title: "新闻动态",
				shade: 0.1,
				shadeClose: true,
				scrollbar: false,
				area: [this.grace.isModile() ? "90%" : "1000px", this.grace.isModile() ? "80%" : "80%"],
				content: "https://www.jiyuanxinxi.net/#/newsdetail?id=" + id,
			});
			// this.$router.push({ name: 'Newsdetail', query: { id: id } });//打开链接方式
		},
		reducepage() {
			this.page = this.page - 1;
			if (this.page < 0) {
				this.page = 0;
			}
			this.shownewsdata = this.news.slice(this.page * 3, (this.page + 1) * 3);
		},
		addpage() {
			this.page = this.page + 1;
			if (this.page > (this.pages - 1)) {
				this.page = this.pages - 1;
			}
			this.shownewsdata = this.news.slice(this.page * 3, (this.page + 1) * 3);
		}
	}
}
</script>
<style scoped>
.arrowarea {
	margin: 30px auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 160px;
	text-align: center;
}

.arrowarea i {
	font-size: 22px;
	cursor: pointer;
	padding: 10px;
}

.arrowarea i:hover {
	color: #333333;
	background-color: #f4f4f4;
	border-radius: 100%;
}
</style>
